import { DayItem, DaysType, SearchItem, SinglePageData } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PlanType = {
    plan: DaysType[]
    selectedDayIndex: number
    loading: boolean
    preview: boolean
    recommended: SearchItem[]
    accomodation: SearchItem | null
}

const initialState: PlanType = {
    plan: [],
    selectedDayIndex: 0,
    loading: false,
    recommended: [],
    preview: false,
    accomodation: null
}

const planSlice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        setPlan(state, action: { payload: DaysType[] }) {
            state.plan = action.payload
        },
        setSelectedDayIndex(state, action: { payload: number }) {
            state.selectedDayIndex = action.payload
        },
        changeDayPlan(state, action: { payload: { ind: number, items: DayItem[] } }) {
            state.plan[action.payload.ind].items = action.payload.items
        },
        setActivityTime(state, action: { payload: { indDay: number, indItem: number, start_time: string | null, end_time: string | null } }) {
            const { payload } = action
            const { indDay, indItem, start_time, end_time } = payload
            state.plan[indDay].items[indItem].start_time = start_time
            state.plan[indDay].items[indItem].end_time = end_time
        },
        setLoading(state, action: { payload: boolean }) {
            state.loading = action.payload
        },
        setRecommended(state, action: { payload: SearchItem[] }) {
            state.recommended = action.payload
        },
        addActivities(state, action: { payload: { dayInd: number, items: SearchItem[] } }) {

            const { dayInd, items } = action.payload

            items.map((item) => {
                if (state.plan[dayInd].items.some((x) => x.slug === item.slug)) return
                return state.plan[dayInd].items.push(item)
            })

        },
        setPreview(state, action: { payload: boolean }) {
            state.preview = action.payload
        },
        moveActivity(state, action: { payload: { dayIndFrom: number, dayIndTo: number, itemToMove: DayItem } }) {
            const { dayIndFrom, dayIndTo, itemToMove } = action.payload
            state.plan[dayIndFrom].items = state.plan[dayIndFrom].items.filter((item) => item.slug !== itemToMove.slug)
            state.plan[dayIndTo].items.push(itemToMove)
        },
        resetPlan(state) {
            state.plan.map((day) => {
                day.items = []
            })
        },
        setAccomodation(state, action: { payload: SearchItem }) {
            state.accomodation = action.payload
        }
    }

})

export const planActions = planSlice.actions
export default planSlice