import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalState, SelectedWishItem } from "@/types";

type ModalType = {
    modalOpen: "login" | "register" | "forgotPassword" | null
    selectedWishItem: SelectedWishItem | null
    currentRoute: string | null
    searchTerm: string
}

const initialState: ModalType = {
    modalOpen: null,
    selectedWishItem: null,
    currentRoute: null,
    searchTerm: ''
};

const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        openModal: (
            state,
            action: PayloadAction<
                "login" | "register" | "forgotPassword" | null
            >,
        ) => {
            state.modalOpen = action.payload;
        },
        setItemSelected: (state, action: PayloadAction<SelectedWishItem>) => {
            state.selectedWishItem = action.payload;
        },
        setCurrentRoute: (state, action: PayloadAction<string>) => {

            state.currentRoute = action.payload;
        },
        closeModal: (state) => {
            state.modalOpen = null;
            state.selectedWishItem = null;
        },
        setSearchTerm: (state, action: { payload: string }) => {
            state.searchTerm = action.payload
        }
    },
});

export const { openModal, setItemSelected, closeModal, setCurrentRoute, setSearchTerm } =
    modalSlice.actions;
export default modalSlice.reducer;
