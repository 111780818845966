import { ProgramParams, WizardFilterData } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ProgramType = {
    progress: number
    preferences: WizardFilterData
    program: ProgramParams
}

const initialState: ProgramType = {
    progress: 0,
    preferences: {
        accomodationType: [''],
        activityType: [''],
        areaType: [''],
        businessType: [''],
        eventType: [''],
        placeType: [''],
    },
    program: {
        accomodation_types: [],
        activity_types: [],
        area: '',
        business_types: [],
        event_types: [],
        place_types: [],
        adults: 1,
        children: 0,
        end_date: null,
        start_date: null,
        include_accomodation: 0,
        hasPlace: false
    }

}

const programSlice = createSlice({
    name: 'program',
    initialState,
    reducers: {
        setProgress(state, action: { payload: number }) {
            state.progress = action.payload
        },
        setInitialProgram(state, action: PayloadAction<ProgramParams>) {
            state.program = action.payload;
        },
        updateProgramProperty<K extends keyof ProgramParams>(state: { program: any }, action: PayloadAction<{ key: K; value: ProgramParams[K] }>) {
            const { key, value } = action.payload

            if(key == 'hasPlace' && value == 'yes'){
                state.program['area'] = null
            }
            if(key == 'include_accomodation' && value == '0' ){
                state.program['accomodation_types'] = []
                state.program['children'] = 0
                state.program['adults'] = 1
            }
            state.program[key] = value;

            localStorage.setItem('program', JSON.stringify(state.program))
        }

    }

})

export const programActions = programSlice.actions
export default programSlice
