import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

import elTranslation from "./el.json"
import enTranslation from "./en.json"

i18n.use(LanguageDetector).use(initReactI18next).use(Backend).init({
    returnObjects: true,
    // resources: {
    //     en: {
    //         translation: enTranslation,
    //     },
    //     el: {
    //         translation: elTranslation,
    //     },
    // },
    // compatibilityJSON: 'v3',
    // lng: 'el', // Initial language
    // fallbackLng: 'el', // Fallback language
    // interpolation: {
    //     escapeValue: false, // React already escapes values
    // },
});

export default i18n;
